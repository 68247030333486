@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';

.root {
}

.main {
  @apply --marketplacePageWidth;
  padding: 48px 24px;

  @media (--viewportMedium) {
    padding: 0 24px;
  }
}

/* heroContainer gives the height for SectionHero */
/* Safari has a bug with vw padding inside flexbox. Therefore we need an extra div (container) */
/* If you've lot of content for the hero, multiple lines of text, make sure to adjust min-heights for each media breakpoint accordingly */
.heroContainer {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    margin-top: 24px;
  }

  @media (--viewportLarge) {
    margin-top: 0;
  }
}

.hero {
  flex-grow: 1;
  justify-content: flex-end;

  @media (--viewportLarge) {
    justify-content: center;
  }
}

.sections {
  margin: 0;
  padding-top: 1px;
}

.section {
  position: relative;
}

.sectionContent {
  margin: 48px 0;
  padding-top: 48px;

  /* Style */
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: rgba(0, 0, 0, 0.1);
}

.sectionContentFirstChild {
  composes: sectionContent;
}
